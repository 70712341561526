// src/store/useAuthStore.ts
import { defineStore } from 'pinia';
export const useAuthStore = defineStore('auth', {
    state: () => {
        return {
            isAuthenticated: false,
            user: null,
        };
    },
    actions: {
        login(user_email) {
            user_email = user_email.toLowerCase();
            const storedEmails = process.env.VUE_APP_WHITELISTED_EMAIL;
            if (storedEmails) {
                const emails = storedEmails.split(',');
                if (emails.some(e => e.toLowerCase() === user_email)) {
                    this.isAuthenticated = true;
                    this.user = user_email;
                    return;
                }
            }
        },
    },
});
